var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 64",
      "enable-background": "new 0 0 64 64",
      "width": _vm.width,
      "height": _vm.height
    }
  }, [_c('g', {
    attrs: {
      "fill": _vm.fill
    }
  }, [_c('path', {
    attrs: {
      "d": "M58.2 11.5H26.9c-2.5 0-4.6 2.1-4.6 4.6v.7H8.6c-.9 0-1.7.5-2.1 1.3l-5 10.1c-.2.3-.2.7-.2 1.1v15.6c0 1 .8 1.8 1.8 1.8H6c.5 3.6 3.6 6.3 7.3 6.3s6.8-2.8 7.3-6.3H42c.5 3.6 3.6 6.3 7.3 6.3s6.8-2.8 7.3-6.3h3.7c1.4 0 2.5-1.1 2.5-2.5V16c0-2.5-2.1-4.5-4.6-4.5zM9.3 20.2h13.1v7H5.8l3.5-7zm3.9 29.2c-2.2 0-3.9-1.8-3.9-3.9s1.8-3.9 3.9-3.9 3.9 1.8 3.9 3.9-1.7 3.9-3.9 3.9zm0-11.3c-3.2 0-6 2.1-7 5H4.8V30.8h17.6v12.3h-2.1c-1.1-2.9-3.8-5-7.1-5zm36.1 11.3c-2.2 0-3.9-1.8-3.9-3.9s1.8-3.9 3.9-3.9 3.9 1.8 3.9 3.9-1.8 3.9-3.9 3.9zm10-6.3h-3c-1-2.9-3.8-5-7-5s-6 2.1-7 5H25.8V16c0-.6.5-1.1 1.1-1.1h31.3c.6 0 1.1.5 1.1 1.1v27.1z"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }